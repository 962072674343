/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.



.menu-header {
  height: 200px;

  ion-toolbar {
    height: 100%;

    img {
      max-width: 100%;
    }
  }
  margin-bottom: 25px;
}

ion-item {
  --padding-start: 16px;
  --highlight-color-focused: var(--ion-color-dark);
}

ion-item.active {
  border-left: 10px solid #1b171b;
  --padding-start: 6px;
}

/*Fixed label beavoiur bug from Ionic see: https://github.com/ionic-team/ionic/issues/18531 */
.item-has-focus .label-stacked.sc-ion-label-md-h {
  color: var(--ion-color-dark);
}

// Modals
.sc-ion-modal-md-h,
.sc-ion-modal-ios-h {
  --width: 60%;
  --height: 55%;
  --border-radius: 5px;
}

.quick-event-modal.sc-ion-modal-md-h,
.sc-ion-modal-ios-h {
  --width: 60%;
  --min-height: 40%;
  --max-height: 55%;
  --border-radius: 5px;
}

.input-modal.sc-ion-modal-md-h,
.sc-ion-modal-ios-h {
  --width: 60%;
  --height: 80%;
  --border-radius: 5px;
}

app-conditional-modal,
app-quick-event-modal,
app-success-modal,
app-no-licenses-modal,
app-input-modal,
app-error-modal {
  ion-button {
    --box-shadow: none;
  }
}

.call-modal-class {
  --width: 50%; 
  --height: 60%;
  --border-radius: 12px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wifi-input-modal-class {
  --width: 40%; 
  --height: 35%;
}

ion-modal {
  //--box-shadow: -6px -12px 18px #888888, -6px 12px 18px #888888, 6px 12px 18px #888888, 6px -12px 18px #888888;
  --box-shadow: 5px 10px 18px #888888;
  
}
// ion-modal.auto-height {
//     &.bottom {
//         align-items: flex-end;
//     }

//     --height: auto;

//     ion-page {
//         position: absolute;
//         display: block;
//         contain: content;
//         .inner-content {
//             max-height: 80vh;
//             overflow: auto;
//             padding: 10px;
//         }
//     }
// }

.modal-btn {
  position: absolute;
  right: 0;
  z-index: 10;
}

.popover-content,
.sc-ion-popover-ios {
  --width: 25%;

  --border-radius: 5px;

  ion-radio-group {
    ion-item {
      --border-style: none;
    }
  }
}



@media (max-width: 800px) {
  .popover-content,
  .sc-ion-popover-ios {
    --width: 45%;
  }
}

.menu-content-open {
  cursor: auto;
}

/*Loading Controller*/

.loading {
  --spinner-color: var(--ion-color-dark);
  --height: 150px;
  --width: 200px;
  .loading-wrapper {
    flex-direction: column;
    border-radius: 5px;

    .sc-ion-loading-md.md.spinner-crescent.hydrated {
      height: 40px;
      width: 40px;
    }

    .loading-content.sc-ion-loading-md {
      margin: 10px 0;
      font-size: 18px;
    }
  }
}



.nt-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  position: relative;
  height: 100vh;

  ion-spinner {
    transform: scale(3);
  }
}

.nt-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .alert-radio-label.sc-ion-alert-md {
//   white-space: pre-wrap;
// }