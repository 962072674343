@font-face {
    font-family: 'Open Sans';
    src: url('../assets/font/OpenSans-Light.ttf');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url('../assets/font/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/font/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/font/OpenSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/font/OpenSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}